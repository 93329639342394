import { RouteRecordRaw } from "vue-router";

import { Permission } from "../api/models/user";

const routes: RouteRecordRaw[] = [
  //cadastro
  {
    path: "/",
    component: () => import("../components/LayoutNavbarHorizontalAndFooter.vue"),
    children: [
      {
        path: "/",
        component: () => import("../views/restaurant/Home.vue"),
      },
      {
        path: "/cadastro",
        name: "Cadastro",
        component: () => import("../views/restaurant/signUp/SignUp.vue"),
      },
    ],
  },
  //itens sem tabs, numerados por prioridade:
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      //1-pedidos
      {
        path: "pedidos/:id?",
        name: "Pedidos",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/restaurant/order/OrderView.vue"),
          table: () => import("../views/restaurant/order/OrdersTable.vue"),
        },
        meta: { requiresAuth: true, priority: 1, permission: Permission.ORDERS },
      },
      //3-restaurante
      {
        path: "restaurante",
        name: "Restaurante",
        component: () => import("../views/restaurant/Restaurant.vue"),
        meta: { requiresAuth: true, priority: 3, permission: Permission.RESTAURANT },
      },
      //4-imagens
      {
        path: "banco-de-imagens/:id?",
        name: "Banco de imagens",
        component: () => import("../views/restaurant/ImageBank.vue"),
        meta: { requiresAuth: true, priority: 4, permission: Permission.IMAGE_BANK },
      },
      //5-avaliações
      {
        path: "avaliacoes",
        name: "Avaliações",
        component: () => import("../views/restaurant/Reviews.vue"),
        meta: { requiresAuth: true, priority: 5, permission: Permission.REVIEWS },
      },
      //6-financeiro
      {
        path: "financeiro",
        component: () => import("../views/restaurant/financial/FinancialView.vue"),
        //TODO corrigir permissões financeiro e contestações (também ver UserForm)
        meta: { requiresAuth: true, priority: 6, permission: Permission.USERS },
      },
      {
        path: "financeiro/historico-mensalidade",
        component: () => import("../views/restaurant/financial/SubscriptionHistoryTable.vue"),
        meta: { requiresAuth: true, priority: 6, permission: Permission.FINANCIAL },
      },
      {
        path: "financeiro/resumo-fatura/:brand",
        component: () => import("../components/VouchersDetails.vue"),
        meta: { requiresAuth: true, priority: 6, permission: Permission.FINANCIAL },
      },
      {
        path: "financeiro/pagamento-fatura/:id",
        component: () => import("../views/restaurant/financial/VoucherPaymentView.vue"),
        meta: { requiresAuth: true, priority: 6, permission: Permission.FINANCIAL },
      },
      //7-relatórios
      {
        path: "relatorios/:id?",
        name: "Relatórios",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/restaurant/order/OrderView.vue"),
          table: () => import("../views/restaurant/OrdersReports.vue"),
        },
        meta: { requiresAuth: true, priority: 7, permission: Permission.ORDERS },
      },
      //8-contestações
      {
        path: "contestacoes-restaurante/:id?",
        name: "Contestações",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/restaurant/complaints/ComplaintsUserDetails.vue"),
          table: () => import("../views/restaurant/complaints/ComplaintsUserView.vue"),
        },
        meta: { requiresAuth: true, priority: 8, permission: Permission.COMPLAINTS },
      },
    ],
  },
  //itens com tabs:
  //9-configurações
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    props: {
      tabItems: [
        { name: "Dados pessoais", path: "/minha-conta" },
        { name: "Usuários do painel", path: "/usuarios" },
        { name: "Histórico de uso", path: "/historico-de-uso" },
      ],
    },
    children: [
      {
        path: "minha-conta",
        name: "Minha conta",
        component: () => import("../views/settings/MyAccount.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "usuarios/:id?",
        name: "Usuários",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/settings/users/UserForm.vue"),
          table: () => import("../views/settings/users/UserTable.vue"),
        },
        meta: { requiresAuth: true, priority: 9, permission: Permission.USERS },
      },
      {
        path: "historico-de-uso",
        name: "Histórico de uso",
        component: () => import("../views/settings/UsageHistory.vue"),
        meta: { requiresAuth: true, permission: Permission.USERS },
      },
    ],
  },
  //2-cardapio
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    props: {
      hasDynamicTabItems: true,
      tabItems: [
        {
          name: "Editar seções",
          path: "/editar-secoes",
          customStyle: "font-bold text-secondary",
        },
      ],
    },
    children: [
      {
        path: "cardapio/:id?",
        name: "Cardápio",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/restaurant/menu/MenuForm.vue"),
          table: () => import("../views/restaurant/menu/MenuTable.vue"),
          isCrudTableFilteredById: true,
          extraQueryParam: "itemId",
        },
        meta: { requiresAuth: true, priority: 2, permission: Permission.MENU },
      },
    ],
  },
  //2-cardapio-editar seções
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/restaurant/menu/editSection/SectionForm.vue"),
          table: () => import("../views/restaurant/menu/editSection/SectionTable.vue"),
        },
        path: "editar-secoes/:id?",
        name: "Editar seções",
      },
    ],
    meta: { requiresAuth: true, priority: 2, permission: Permission.MENU },
  },
];

export default routes;
